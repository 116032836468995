/**
 * START => Loading JQuery
 * * Loading 結構 👉 <div class="loader" style="height: 0px;"><img src=""></div>
 * * 顯示 👉 $('.loader').showloader()
 * * 隱藏 👉 $('.loader').hideloader()
 */
$.fn.hideloader = function () {
    this.css("height", 0);
    setTimeout(() => this.children().hide(), 200);
};
$.fn.showloader = function () {
    this.css("height", "100vh");
    this.children().show();
};
/** END => Loading JQuery */


/**
 * Element Value 移除特殊符號
 * @param  {} target DOM Element
 */
function removeSpecialCharacter(target) {
    // target.value = target.value.replace(/[^\w\s]/gi, '');
    target.value = target.value.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
    );
}


/**
 * 頁面跳轉
 * @param  {} pageurl 跳轉頁面檔名+副檔名
 */
function gotolocation(pageurl) {
    location.href = pageurl;
}


/**
 * 頁面跳轉
 * @param  {} pageurl 跳轉頁面檔名+副檔名
 * @param  {} dataObj 要使用的參數數值 {key:value}
 */
function gotoMergeLocation(pageurl, dataObj) {
    let param = "";
    for (const key in dataObj) {
        if (dataObj[key] != null) {
            param += `${key}=${dataObj[key]}&`;
        }
    }
    param = param.trim().slice(0, -1);
    let url = pageurl + "?" + param;
    location.href = url;
}

/**
 * Bootstrap5 Form 驗證
 */
function initialFormValidation() {
    (() => {
        "use strict";
        const forms = document.querySelectorAll(".needs-validation");
        Array.from(forms).forEach((form) => {
            form.addEventListener(
                "submit",
                (event) => {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    form.classList.add("was-validated");
                },
                false
            );
        });
    })();
}

/**
 * 回傳網址傳送的指定參數值
 * @param  {} valueName 參數名稱
 */
function getQueryString(valueName) {
    var url = window.location.href;
    if (url.indexOf("?") != -1) {
        const ary1 = url.split("?")[1].split("&");
        const aryName = ary1.map((x) => x.split("=")[0]);
        const aryValue = ary1.map((x) => x.split("=")[1]);
        const index = aryName.indexOf(valueName);
        const result = aryValue[index];
        return result;
    }
}
/**
 * localstorage僅保留特定key
 * @param  {string} savekey 使用","區隔多參數
 */
function cleanKeywithout(savekey) {
    var arr_savekey = savekey.split(",");
    var keys = Object.keys(localStorage),
        i = keys.length;
    while (i--) {
        var isBool = false;
        for (var j = 0; j < arr_savekey.length; j++) {
            if (keys[i] == arr_savekey[j]) {
                isBool = true;
            }
        }
        if (!isBool) {
            localStorage.removeItem(keys[i]);
        }
    }
}

/**
 * 儲存有期限的localstorage
 * *需與 timeCountdown(key) 一起使用
 * @param  {} key
 * @param  {} value
 * @param  {} expire
 */
function storeExpire(key, value, expire) {
    let obj = {
        time: new Date().getTime(),
        value: value,
        expire: expire,
    };
    let objStr = JSON.stringify(obj);
    localStorage.setItem(key, objStr);
    timeCountdown(key);
}

/**
 * 倒數移除localstorage
 * @param  {} key
 */
function timeCountdown(key) {
    var timer = setInterval(function () {
        if (localStorage.getItem(key)) {
            var name = localStorage.getItem(key);
            var nameObj = JSON.parse(name);
            if (new Date().getTime() - nameObj.time >= nameObj.expire) {
                localStorage.removeItem(key);
            }
        } else {
            clearInterval(timer);
        }
    }, 1000);
    return timer;
}

/**
 * 產生亂數
 */
function generateRandom() {
    return (r = Math.floor(Math.random() * (999999 - 111111 + 1)) + 111111);
}

/**
 * 驗證二個參數不管大小寫和空格，數值是否相同
 * @param  {string} string1 參數1
 * @param  {string} string2 參數2
 * @returns {boolean} 驗證是否成功
 */
function ValidCaptchaTwoValue(string1, string2) {
    var str1 = removeSpaces(string1);
    var str2 = removeSpaces(string2);
    if (str1.toUpperCase() == str2.toUpperCase()) {
        return true;
    } else {
        return false;
    }
}

/**
 * 移除字串中所有空格
 * @param  {string} string
 */
function removeSpaces(string) {
    if (typeof string === "string" || string instanceof String) {
        return string.split(" ").join("");
    }
}

/**
 * 驗證表格欄位增減class
 * @param  {boolean} action true=驗證正確、false=驗證錯誤
 */
$.fn.validate = function (action) {
    if (action == true) {
        this.removeClass("is-invalid");
        // this.addClass("is-valid");
    } else {
        // this.removeClass("is-valid");
        this.addClass("is-invalid");
    }
};

/**
 * 驗證欄位
 */
$.fn.validateCondition = function () {
    let isValidated = false;
    let ErrMsg = "";
    let emptyValue = "";
    const IsRequired = this.prop("required");
    // if (this[0].localName == 'select') { emptyValue = 0 }
    const elementValue = {
        value: (this.val() ? this.val() : "").trim(),
        emptyData: emptyValue,
    };
    try {
        if (IsRequired && checkDataIsEmpty(elementValue)) {
            throw this.attr("data-msg-required");
        }
        if (
            this.attr("minlength") !== undefined &&
            this.val().length < this.attr("minlength")
        ) {
            throw this.attr("data-msg-min");
        }
        if (
            this.attr("maxlength") !== undefined &&
            this.val().length > this.attr("maxlength")
        ) {
            throw this.attr("data-msg-max");
        }
        isValidated = true;
        this.removeClass("is-invalid");
        // this.addClass("is-valid");
    } catch (error) {
        isValidated = false;
        ErrMsg = error;
        // this.removeClass("is-valid");
        this.addClass("is-invalid");
    }

    const resultObj = {
        isValidated,
        ErrMsg
    };
    return resultObj;
};
/**
 * 驗證欄位-前端 OnBegin 呼叫
 */
function validateAction(formEl = "") {
    let allResult;
    $(
        `${formEl ? formEl : ""} input[type!="radio"], ${formEl ? formEl : ""
        } select, ${formEl ? formEl : ""} textarea`
    ).each(function () {
        $(this).removeClass("is-invalid");
        const IsRequired = $(this).prop("required");
        if (!IsRequired && ($(this).val()?.length === 0 || $(this).val() === null))
            return;
        const result = $(this).validateCondition();
        if (!result.isValidated) {
            $(`#${$(this).attr("id")}_feedback`).show();
            $(`#${$(this).attr("id")}_feedback`).text(result.ErrMsg);
            allResult = false;
        } else {
            $(`#${$(this).attr("id")}_feedback`).hide();
        }
    });
    //radio 另外處理
    const arr_radioInputGroup = getUniqueName(
        `${formEl ? formEl : ""} input[type="radio"][name]`,
        "name"
    );
    arr_radioInputGroup.forEach(function (el) {
        const name = $(el).attr("name");
        $(`${formEl ? formEl : ""} input[type="radio"][name="${name}"]`).validate(
            false
        );
        const IsRequired = $(el).prop("required");
        if (!IsRequired) return;
        if (
            $(`${formEl ? formEl : ""} input[type="radio"][name="${name}"]:checked`)
            .length === 0
        ) {
            $(`#${$(this).attr("id")}_feedback`).show();
            $(`#${$(this).attr("id")}_feedback`).text(
                $(el).attr("data-msg-required")
            );
            $(`${formEl ? formEl : ""} input[type="radio"][name="${name}"]`).validate(
                false
            );
            allResult = false;
        } else {
            $(`${formEl ? formEl : ""} input[type="radio"][name="${name}"]`).validate(
                true
            );
            $(`#${$(this).attr("id")}_feedback`).hide();
        }
    });

    return allResult === undefined ? true : false;
}
/**
 * 選擇器中，有相同 Attr 的元素只取一個
 */
function getUniqueName(selector, attr) {
    const inps = document.querySelectorAll(selector);
    let unique = [];
    for (var i = 0; i < inps.length; i++) {
        if (!i || inps[i - 1].getAttribute(attr) != inps[i].getAttribute(attr))
            unique.push(inps[i]);
    }
    return unique;
}

/**
 * 檢查手機號碼格式
 * @param  {string} phoneNum 手機號碼
 */
function checkPhoneFormat(phoneNum) {
    if (!/^09[0-9]{8}$/.test(phoneNum)) {
        return false;
    }
    return true;
}

/**
 * 檢查信箱格式
 * @param  {string} email 信箱
 */
function checkEmailFormat(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

/**
 * 檢查字串是否為空值
 * @param  { Object } objInput
 * @param {string} objInput.value
 * @param {string} objInput.emptyData
 */
function checkDataIsEmpty(objInput) {
    let objData = {
        value: "",
        emptyData: "",
    };

    Object.keys(objInput).forEach(function (key) {
        if (key in objInput) {
            objData[key] = objInput[key];
        }
    });

    if (objData.value == objData.emptyData) {
        return true;
    }
    return false;
}

/**
 * 顯示或隱藏密碼
 * @param  { string } btn_el_Id 控制顯示/隱藏的按鈕
 * @param {string} password_el_id 密碼欄位
 * @param {string} toggleclass 切換的 className
 */
function showHidePassword(btn_el_Id, password_el_id, toggleclass) {
    const togglePassword = document.querySelector(btn_el_Id);
    const password = document.querySelector(password_el_id);

    togglePassword.addEventListener("click", function () {
        const type =
            password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        this.classList.toggle(`${toggleclass ? toggleclass : "fa-eye"}`);
    });
}

/**
 * 限制上傳檔案數量
 * @param {string} event
 * @param {number} limitNum 限制數量
 */
function limitFilesNum(event, limitNum = 0) {
    var $fileUpload = $(event.target);
    if (limitNum !== 0 && parseInt($fileUpload.get(0).files.length) > limitNum) {
        $fileUpload.addClass("is-invalid");
        $(`#${$fileUpload.attr("id")}_feedback`).show();
        $(`#${$fileUpload.attr("id")}_feedback`).text(
            $fileUpload.attr("data-msg-limit")
        );
    } else {
        $fileUpload.removeClass("is-invalid");
        $(`#${$fileUpload.attr("id")}_feedback`).hide();
    }
}

/**
 * START => Button：刪除自己父層
 */
function deleteItself(event, parentSelector) {
    const $target = $(event.target);
    $target.parents(parentSelector).remove();
}

/** END => Button：刪除自己父層 */

/**
 * (未完成)
 * 上傳預覽圖片
 * 阻擋非圖片檔
 */
function previewImages(previewEl, limitNum) {
    var preview = document.querySelector(previewEl);
    if (this.files) {
        [].forEach.call(this.files, readAndPreview);
    }

    function readAndPreview(file) {
        // File type validator based on the extension
        if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
            return alert(file.name + " 不是圖片檔");
        }
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            var image = new Image();
            image.height = 100;
            image.title = file.name;
            image.src = this.result;
            preview.appendChild(image);
        });
        reader.readAsDataURL(file);
    }
}

/**
 * 毫秒數轉換成時和分
 * @param {*} num
 * @returns
 */
function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
}

function convertMsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
        seconds
    )}`;
}

/**
 * 加日期
 * @param {*} currentDate
 * @param {*} daysToAdd
 * @returns
 */
function addDaysToDate(currentDate, daysToAdd) {
    daysToAdd = daysToAdd || 0;

    // Instantiate a new object based on the current Date
    const futureDate = new Date(currentDate);

    // Adding the number of days
    futureDate.setDate(futureDate.getDate() + daysToAdd);

    return futureDate;
}

/**
 * 頁面切換
 * * pageName 為 data-page 的 value
 * * setBackPage 為選填，如需要動態跳頁的話可搭配使用
 */
function tran(pageName, setBackPage = "") {
    if (setBackPage !== "") {
        $('.wiper[role="wiper-control"]').attr("data-back_page", setBackPage);
    }
    $("div[data-page=" + pageName + "]")
        .removeClass("closed")
        .addClass("open");
    $("div.open[data-page!=" + pageName + "]")
        .removeClass("open")
        .addClass("closed");
}


/**
 * 日期格式轉換 yyyy-mm-dd
 * @param {*} date 
 * @returns 
 */
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * 身分證驗證
 * @param {*} id 
 * @returns 
 */
function verifyId(id) {
    try {
        id = id.trim();
        let verification = id.match("^[A-Z][12]\\d{8}$")
        if (!verification) {
            return false
        }

        let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
        let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]

        id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

        let checkSum = 0
        for (let i = 0; i < id.length; i++) {
            let c = parseInt(id[i])
            let w = weights[i]
            checkSum += c * w
        }

        return checkSum % 10 == 0
    } catch (error) {
        return false
    }
}