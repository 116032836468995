/**
 * START => DataTables 預設共用參數
 */
let table_config = {
  scrollX: true,
  searching: false,
  processing: true,
  pagingType: 'full_numbers',
  pageLength: 20,
  language: {
    emptyTable: '無資料',
    paginate: {
      first: '第一頁',
      last: '最末頁',
      next: '下一頁',
      previous: '上一頁'
    },
    info: "第 _START_ 至 _END_ 筆，總共 _TOTAL_ 筆",
    infoEmpty: "0 筆資料",
    lengthMenu: `<select class="form-control p-1">
    <option value="10">10筆</option>
    <option value="20">20筆</option>
    <option value="50">50筆</option>
    <option value="100">100筆</option>
    </select> / 每頁`
  },
  dom: '<"top"B>t<"bottom d-flex justify-content-between"i<"d-flex align-items-end ml-auto"pl>><"clear">',
  lengthMenu: [
    [10, 20, 50, 100],
    [10, 20, 50, 100]
  ],
  drawCallback: function (oSettings) {
    var totalPages = this.api().page.info().pages;
    if (totalPages <= 1) {
      $(`#${$(this).attr('id')}_paginate`).hide();
    } else {
      $(`#${$(this).attr('id')}_paginate`).show();
    }
  }
}
/** END => DataTables 預設共用參數 */



/** 
 * START => SweetAlet2：預設確認 + 取消顏色
 */
const swalBtn = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger mx-1",
    cancelButton: "btn btn-secondary mx-1"
  },
  buttonsStyling: false
});
/** END => SweetAlet2：預設確認 + 取消顏色 */



/** 
 * START => DataTables：縮放 Left Sidebar 時，重製表格
 */
// $(document).on('collapsed-done.lte.pushmenu', () => {
//   DataTable.tables({ visible: true, api: true }).columns.adjust();
// })
// $(document).on('shown.lte.pushmenu', () => {
//   setTimeout(() => DataTable.tables({ visible: true, api: true }).columns.adjust(), 300);
// })
/** END => DataTables：縮放 Left Sidebar 時，重製表格 Table */



/** 
 * START => Form：若為 disabled 則 size 為 value 長度
 */
$(document).ready(function () {
  $("input:disabled").attr('size', function () {
    return $(this).val().length;
  })
});
/** END => Form：若為 disabled 則 size 為 value 長度 */